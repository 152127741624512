<template>
  <div class="yj-frame-main">
    <div class="up-banner">
      <h1>售后保障</h1>
      <img src="../../assets/image/fwzc/1.png" alt="">
    </div>
    <div class="newContent">
      <h3>售后保障承诺</h3>
      <div class="content">
      <p>尊敬的客户：感谢您选择禹江科技产品和服务！就本文件项下产品，我们将向您提供以下保证和保修服务。本文件所称的“您”是指购买产品的任何个人或机构。本文件中所称的“我们”是指禹江科技及服务提供商。</p>
      
      <h6>1.0 保证范围</h6>
      <p class="indent">我们保证，如产品在正常使用和维护条件下，因材料、工艺或制造问题导致性能故障，在保修期内，我们将按本文件规定为您提供修理、更换或退货等保修服务。 “正常使用和维护条件”是指产品的安装、使用、维护、保管、运输等符合使用说明要求，并用于合理的预期目的或用途。“影响正常使用”是指产品不能实现其标准性能参数所描述的功能。除事先声明或依法或依约定应由您负担费用外，我们不就保修服务向您另外收取费用。以上是我们就产品质量和服务所做的全部保证，无其他明示或默示的保证。如销售商或其他任何第三方对您有额外承诺，您应向这些第三方要求兑现，禹江科技对此不承担责任。</p>
      
      <h6>2.0 保修期</h6>
      <p class="indent">2.1产品及部件的相应保修期按相关的合同执行。保修期自产品首次购买之日起算，购买日以购买产品的发票日期为准。如无有效发票，则保修期将自产品出厂日推算。产品发票日期晚于产品实际交付日的，保修期自产品实际交付日起算。</p>
      <p class="indent">2.2在保修范围内修理或更换后的产品或部件，在剩余保修期内继续享有保修，剩余保修期不足六个月的按六个月计算，禹江科技与您签署的合同或适用的相关法律对此另有规定的，则按合同或法律规定执行。</p>
     
      <h6>3.0 如何获得保修服务</h6>
      <p class="indent">在保修期内，如产品发生性能故障，我们将按本文件提供保修服务，具体服务获取方式：</p>
      <p>3.1在线支持服务，您可通过关注禹江科技微信公众号获得在线支持服务。</p>
      <p>3.2热线支持服务，拨打我司客户服务热线 025-58113035 获得电话支持服务，客服热线提供产品使用咨询和故障排查指导服务。</p>
      <p>3.3送修服务，可以将设备送修或寄修至距离您最近的禹江科技售后服务站获得本地维修服务，您可登录禹江科技官网http://www.jsyj56.com 获取最新服务站联系方式，或是通过扫描下方二维码申请在线送修。</p>
      <p>3.4 保修凭证，申请保修时，您应提供清晰、完整和准确的销售发票，这是您有权获得保修的凭证。在购买产品时，您应向销售商索取并妥善保管完整填写的销售发票（含产品型号和序列号）。如发票内容与产品实际不符、被涂改或无法辨认，视为无效。如您不能提供有效凭证，则在我们可确认产品或部件属于保修范围的情况下，也可为您提供保修，但这不作为我们的义务。当您申请保修服务时，需要遵守我们规定的一些必要程序，包括提供必要的相关信息，遵循特定的鉴定和解决问题步骤。</p>
      <p>3.5 申请服务前准备，在适用的情况下，在提供服务前，您应：①遵守指定的服务申请程序；②备份产品中包含的所有程序和数据或确保它们的安全，禹江科技不对使用或维修过程中任何因数据丢失而导致的损失承担责任；③提供必要的支持和配合以便我们提供服务，包括提供系统密钥或口令、故障现象描述及必要的场地等；④从产品中删除所有受法律保护的保密和个人信息，如您不能删除产品中的此类信息，您需在申请保修时告知服务提供方；⑤若无特殊需要，请不要在送修的产品中附带周边硬件，线缆等物品，如有特殊需要请事先和禹江科技联系并在送修时附随相关清单，以免造成不必要的损失；⑥请谨慎选择寄送方式和运输商并建议购买运输保险。</p>
     
      <h6>4.0 保修解决方案</h6>
      <p>4.1产品故障以修理为解决原则，在可行的情况下，我们可能会尝试通过网络、电话或其他远程帮助方式来诊断和解决您的产品故障。如有些问题可通过您自行安装指定的软件更新或替换件方式解决，则我们会指导您获得和安装此类软件更新或替换件。如上述方式不适用或无法解决问题，您可将设备送修或寄修至距离您最近的禹江科技售后服务站获得本地维修服务。</p>
      <p>4.2 若因更新换代等原因原产品停产，则默认以替代款或性能不低于原款的产品进行替换处理，保内免费处理，保外有偿处理</p>

      <h6>5.0 更换和退货</h6>
      <p>5.1退货服务，自首次购买之日起7日内（含），如所购产品发生性能故障，经禹江科技售后服务工程师测试确认确为产品本身问题导致的故障后，您可以选择退货、换货或者免费维修。</p>
      <p>5.2换货服务，自首次购买之日起30日内（含），如所购产品发生性能故障，经禹江科技售后服务工程师测试确认确为产品本身问题导致的故障后，您可以选择换货或者免费修理。禹江科技将为您免费更换同型号同规格的产品或配件；若同型号同规格的产品或配件已经停产，将为您更换不低于原产品性能的产品或配件。</p>
      <p>5.3除非另有规定，产品退货或换货由产品销售商负责，产品随附物品及包装应一并退还，更换下来的产品或部件归禹江科技所有，而更换后的产品或部件将成为您的财产。</p>
       
      <h6>6.0 不保证的范围</h6>
      <p>本文件项下的保证不适用于下列情况：①超出规定的保修期限的；②因误用、意外、改装、不适当的物理或操作环境、自然灾害、电涌及不当维护或保管导致的故障或损坏；③第三方产品、软件、服务或行为导致的故障或损坏；④产品使用过程中发生的正常脱色、磨损和消耗；⑤产品可以不间断或无错误地正常运行；⑥数据丢失或损坏；⑦消耗零部件，如电池或保护膜等随时间推移而耗损的零部件，除非是因材料或工艺缺陷而发生的故障；⑧不能出示产品有效保修凭证和有效原始购物发票或收据，产品原序列号标签有涂改、替换、撕毁的现象、产品没有序列号或保修凭证上的产品型号或编号与产品实物不相符合的；⑨未按随附的说明、操作手册使用产品，或者产品未用于预定功能或环境，禹江科技经证实后确定您违反操作手册的任何其他情况；⑩产品的齐备性和外观状态不属保证范围，您应在接受产品时当场检验并对任何不符提出异议。</p>

      <h6>7.0 使用个人信息</h6>
      <p>我们可能会保存、使用并处理您提供的保修问题和联系信息，包括姓名、电话号码、地址和电子邮件地址，仅用于提供规定的服务，我们可能会与您联系，进行满意度调查或通知您有关产品召回或安全问题。为此，我们可能会将您的信息发送给我们的相关机构，包括代表我们执行某些业务的实体。我们也可能会依有关法律机构的要求向其披露相关信息。</p>

      <h6>8.0 责任限制</h6>
      <p>如禹江科技违约或需承担其他责任，您可要求禹江科技赔偿损失。但禹江科技的赔偿责任仅限于您的实际直接损失，且对以下各项不承担任何责任：①超出您购买产品实际支付价款的金额；②任何有关获得替代货物、技术、服务或权利的成本和费用；③数据损坏或丢失；④使用中断或延迟导致的损失。此责任限制同样适用于产品销售商和服务商。这是禹江科技及产品销售商和服务商共同承担的最高赔偿限额。以上责任限制仅在法律允许的范围内适用，不适用于那些依法不能通过合同或其他方式排除或限制的责任。</p>

      <h6>9.0 其他权利</h6>
      <p>本文件授予您特定的权利，您可能依法还拥有其他权利。本文件的任何内容均不会排除和限制消费者的权利，也不会减轻或免除我们对消费者的责任。如您是为生活消费需要而购买产品的消费者用户，则我们为您提供的保修将不低于法定保修标准。法定保修范围内的保修按法律规定执行，法定保修范围外的保修及法律允许另行规定的内容，按本文件执行。</p>

      <h6>10.0 全国联保服务</h6>
      <p>本文件项下的保修仅适用于在中国大陆司法管辖区境内（不包括香港、澳门和台湾）销售的产品，且仅在该区域内提供保修服务。</p>

      <h6>11.0 一般条款</h6>
      <p>本文件依照中国大陆司法管辖区的法律制定和解释。本文件中，法律是指国家现行具有强制执行效力的法律规范性文件，保修等同于保证或质量保证，法定保修是指适用于您产品的国家规定，所提及的时限均按法律有关时限的规定解释。如本文件的任何条款被有管辖权的法律机关裁定为部分或全部无效的，不影响其他部分的效力，本文件其他内容在未被裁定为无效的范围内仍然具有完全的效力。</p>
      
      <div class="bot">
        <h5>江苏禹江科技有限公司</h5>
        <h5>2023年1月13日</h5>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: "aftersale",
  data(){
    return {
      
    };
  },
  mounted() {

  },
  methods: {
    
  },
}
</script>
<style lang="scss" scoped>
.newContent{
  width: 1040px;
  background: #FFFFFF;
  padding: 40px 80px;
  margin: 3.125rem auto;
  border-radius: 10px;
  padding-bottom: 100px;
  h3{
    text-align: center;
    font-size: 18px;
    margin-bottom: 30px;
  }
  .content{
    h6{
      font-size: 14px;
      letter-spacing: 2px;
    }
    p{
      line-height: 28px;
      font-size: 14px;
      color: #666666;
      margin: 15px 0;
      text-indent: 26px;
    }
  }
  .bot{
    float: right;
    h5{
      margin-top: 10px;
      font-size: 14px;
    }
  }
}
</style>
